import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getAuth, updateProfile } from "firebase/auth";
import { getFirestore, addDoc, collection, writeBatch, doc, query, getDocs,where } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
//import Button from '@mui/material/Button';

const Signup = () => {
  const auth = getAuth();
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayname, setDisplayname] = useState("");
  const [clubName, setClubName] = useState("");
  const [error, setError] = useState("");
  const [player1, setPlayer1] = useState("");
  const [player2, setPlayer2] = useState("");
  const [player3, setPlayer3] = useState("");
  const [player4, setPlayer4] = useState("");
  const [player5, setPlayer5] = useState("");
  const [playerCaptainType, setPlayerCaptainType] = useState("");
  const [playerCaptain, setPlayerCaptain] = useState("");
  const [doublesListSelected, setDoublesListSelected] = useState("");
  const [doublesListSelected1, setDoublesListSelected1] = useState("");
  const [doublesListSelected2, setDoublesListSelected2] = useState("");
  const [doublesListSelected3, setDoublesListSelected3] = useState("");
  const [doublesListSelected4, setDoublesListSelected4] = useState("");
  const [doublesListSelected5, setDoublesListSelected5] = useState("");
  const [player1Type, setPlayer1Type] = useState("");
  const [player2Type, setPlayer2Type] = useState("");
  const [player3Type, setPlayer3Type] = useState("");
  const [player4Type, setPlayer4Type] = useState("");
  const [player5Type, setPlayer5Type] = useState("");
  const allPlayers = [
    {name: playerCaptain, type: playerCaptainType},
    {name: player1, type: player1Type},
    {name: player2, type: player2Type},
    {name: player3, type: player3Type},
    {name: player4, type: player4Type},
    {name: player5, type: player5Type}
  ]

const doublesFirstTeam = [
  { name: doublesListSelected, type: "" },
  { name: doublesListSelected1, type: ""  },
];
const doublesSecondTeam = [
  { name: doublesListSelected2, type: "" },
  { name: doublesListSelected3, type: ""  },
];
const doublesThirdTeam = [
  { name: doublesListSelected4, type: "" },
  { name: doublesListSelected5, type: ""  },
];
var filteredKeywords = doublesFirstTeam.filter((name) => !allPlayers.includes(name));
console.log(filteredKeywords)
const doublesFirstTeamCategory = [];
const findMatchingDoublesFirstTeam = doublesFirstTeam.map((group) => {
  allPlayers.map((config) => {
    if (group.name === config.name) {
      doublesFirstTeamCategory.push(config);
    }
  });
  if (doublesFirstTeamCategory[0]?.type === "Mens" && doublesFirstTeamCategory[1]?.type === "Mens"){
    doublesFirstTeamCategory.push({doublesType: 'mens Doubles'});
  }else if (doublesFirstTeamCategory[0]?.type === "Ladies" && doublesFirstTeamCategory[1]?.type === "Ladies"){
    doublesFirstTeamCategory.push({doublesType: 'ladies Doubles'});
  }else if (doublesFirstTeamCategory[0]?.type === "Ladies" && doublesFirstTeamCategory[1]?.type === "Mens" || doublesFirstTeamCategory[0]?.type === "Mens" && doublesFirstTeamCategory[1]?.type === "Ladies"){
    doublesFirstTeamCategory.push({doublesType: 'mixed Doubles'});
  }
});

const doublesSecondTeamCategory = [];
const findMatchingDoublesSecondTeam = doublesSecondTeam.map((group) => {
  allPlayers.map((config) => {
    if (group.name === config.name) {
      doublesSecondTeamCategory.push(config);
    }
  });
  if (doublesSecondTeamCategory[0]?.type === "Mens" && doublesSecondTeamCategory[1]?.type === "Mens"){
    doublesSecondTeamCategory.push({doublesType: 'mens Doubles'});
  }else if (doublesSecondTeamCategory[0]?.type === "Ladies" && doublesSecondTeamCategory[1]?.type === "Ladies"){
    doublesSecondTeamCategory.push({doublesType: 'ladies Doubles'});
  }else if (doublesSecondTeamCategory[0]?.type === "Ladies" && doublesSecondTeamCategory[1]?.type === "Mens" || doublesSecondTeamCategory[0]?.type === "Mens" && doublesSecondTeamCategory[1]?.type === "Ladies"){
    doublesSecondTeamCategory.push({doublesType: 'mixed Doubles'});
  }
});

const doublesThirdTeamCategory = [];
const findMatchingDoublesThirdTeam = doublesThirdTeam.map((group) => {
  allPlayers.map((config) => {
    if (group.name === config.name) {
      doublesThirdTeamCategory.push(config);
    }
  });
  if (doublesThirdTeamCategory[0]?.type === "Mens" && doublesThirdTeamCategory[1]?.type === "Mens"){
    doublesThirdTeamCategory.push({doublesType: 'mens Doubles'});
  }else if (doublesThirdTeamCategory[0]?.type === "Ladies" && doublesThirdTeamCategory[1]?.type === "Ladies"){
    doublesThirdTeamCategory.push({doublesType: 'ladies Doubles'});
  }else if (doublesThirdTeamCategory[0]?.type === "Ladies" && doublesThirdTeamCategory[1]?.type === "Mens" || doublesThirdTeamCategory[0]?.type === "Mens" && doublesThirdTeamCategory[1]?.type === "Ladies"){
    doublesThirdTeamCategory.push({doublesType: 'mixed Doubles'});
  }
});
const allDoubles = [
  {name: doublesFirstTeamCategory[0]?.name},
  {name: doublesFirstTeamCategory[1]?.name},
  {name: doublesSecondTeamCategory[0]?.name},
  {name: doublesSecondTeamCategory[1]?.name},
  {name: doublesThirdTeamCategory[0]?.name},
  {name: doublesThirdTeamCategory[1]?.name}
];
  const { createUser } = UserAuth(); 
  const navigate = useNavigate();
  const db = getFirestore();

  const gender = [
    {
      value: 'Singles Category',
      label: 'Singles Category'
    },
    {
      value: 'Mens',
      label: 'Mens',
    },
    {
      value: 'Ladies',
      label: 'Ladies',
    },
  ];
  const doublesList = [
    {
      value: playerCaptain,
      label: playerCaptain,
    },
    {
      value: player1,
      label: player1,
    },
    {
      value: player2,
      label: player2,
    },
    {
      value: player3,
      label: player3,
    },
    {
      value: player4,
      label: player4,
    },
    {
      value: player5,
      label: player5,
    },
  ];

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();
    setError("");
    var valueArr = allDoubles.map(function(item){ return item.name });
    var isDuplicate = valueArr.some(function(item, idx){ 
        return valueArr.indexOf(item) != idx 
    });
    if(isDuplicate == true){
      alert("There are currently Doubles partners that are selected more than once, please correct and submit again");
    }else{
      try {
        await createUser(email, password);
        await updateProfile(auth.currentUser, {
          displayName: displayname,
        }).then(() => {
          console.log(auth.currentUser.displayName)
        }).catch((error) => {
          console.log(error);
        });
        console.log(isDuplicate);
      // Generate a unique 6-digit code
      const codeLength = 6;

      function generateCode() {
        let code = "";
        for (let i = 0; i < codeLength; i++) {
          code += Math.floor(Math.random() * 10); // Generate a random digit (0-9)
        }
        return code;
      }

      let code = generateCode();
      const codesCollectionRef = collection(db, "codes"); // Replace "codes" with your Firestore collection name

      // Check if the generated code already exists in Firestore
      const querySnapshot = await getDocs(query(codesCollectionRef, where("code", "==", code)));
      const codeExists = !querySnapshot.empty;

      // Loop until a unique code is generated
      while (codeExists) {
        code = generateCode();
        const querySnapshot = await getDocs(query(codesCollectionRef, where("code", "==", code)));
        codeExists = !querySnapshot.empty;
      }

      // Push the generated code to a new Firestore document
      await addDoc(collection(db, "captains"), {
        fullName: playerCaptain,
        teamName: displayname,
        emailAccount: email,
        uniqueCode: code,
      });
      await addDoc(collection(db, 'teams'), {
          clubName: clubName,
          teamName: displayname,
          captain: playerCaptain
        });
      await addDoc(collection(db, 'players'), {
          name: playerCaptain,
          singlesType: playerCaptainType,
          clubName: clubName,
          teamName: displayname,
        });
      await addDoc(collection(db, 'players'), {
        name: player1,
        singlesType: player1Type,
        clubName: clubName,
        teamName: displayname,
      });
      await addDoc(collection(db, 'players'), {
        name: player2,
        singlesType: player2Type,
        clubName: clubName,
        teamName: displayname,
      });
      await addDoc(collection(db, 'players'), {
        name: player3,
        singlesType: player3Type,
        clubName: clubName,
        teamName: displayname,
      });
      await addDoc(collection(db, 'players'), {
        name: player4,
        singlesType: player4Type,
        clubName: clubName,
        teamName: displayname,
      });
      await addDoc(collection(db, 'players'), {
        name: player5,
        singlesType: player5Type,
        clubName: clubName,
        teamName: displayname,
      });
      await addDoc(collection(db, 'doubles'), {
        player1: doublesFirstTeamCategory[0]?.name,
        player1SinglesType: doublesFirstTeamCategory[0]?.type,
        player2: doublesFirstTeamCategory[1]?.name,
        player2SinglesType: doublesFirstTeamCategory[1]?.type,
        doublesType: doublesFirstTeamCategory[2]?.doublesType,
        teamName: displayname,
        clubName: clubName,
      });
      await addDoc(collection(db, 'doubles'), {
        player1: doublesSecondTeamCategory[0]?.name,
        player1SinglesType: doublesSecondTeamCategory[0]?.type,
        player2: doublesSecondTeamCategory[1]?.name,
        player2SinglesType: doublesSecondTeamCategory[1]?.type,
        doublesType: doublesSecondTeamCategory[2]?.doublesType,
        teamName: displayname,
        clubName: clubName,
      });
      await addDoc(collection(db, 'doubles'), {
        player1: doublesThirdTeamCategory[0]?.name,
        player1SinglesType: doublesThirdTeamCategory[0]?.type,
        player2: doublesThirdTeamCategory[1]?.name,
        player2SinglesType: doublesThirdTeamCategory[1]?.type,
        doublesType: doublesThirdTeamCategory[2]?.doublesType,
        teamName: displayname,
        clubName: clubName,
      });
        navigate("/account");
      } catch (e) {
        setError(e.message);
        console.log(e.message);
      }
    }
    setTimeout(() => {
      setloading(false)
 }, 4000)
    
  };
  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div>
        <h1 className="text-2xl font-bold py-2">Team Sign up</h1>
      </div>
      <Form onSubmit={handleSubmit}>
      <Form.Label>Club Name</Form.Label>
          <Form.Control 
            placeholder="Enter Club Name"
            onChange={(e) => setClubName(e.target.value)}
            className="border p-3"
            type="Text"
            required
          />
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Team Name</Form.Label>
          <Form.Control
            placeholder="Enter Team Name"
            onChange={(e) => setDisplayname(e.target.value)}
            className="border p-3"
            type="text"
            required
          />
           <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Team Captain</Form.Label>
          <Form.Control 
            placeholder="Enter Captain Name"
            className="border p-3"
            onChange={ (e) => setPlayerCaptain(e.target.value)}
            type="Text"
            required
          />
          <TextField
          id="filled-select-currency"
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category you will be participating in"
          onChange={ (e) => setPlayerCaptainType(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          <Form.Label>Players (Singles Category)</Form.Label>
          <Form.Control
            placeholder="Enter Player Name"
            className="border p-3"
            type="text"
            onChange={(e) => setPlayer1(e.target.value)}
            required
          />
       <TextField
          id="filled-select-currency"
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category they will be participating in"
          onChange={(e) => setPlayer1Type(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Control
            placeholder="Enter Player Name"
            className="border p-3"
            type="text"
            onChange={(e) => setPlayer2(e.target.value)}
            required
          />
       <TextField
          id="filled-select-currency"
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category they will be participating in"
          onChange={ (e) => setPlayer2Type(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Control
            placeholder="Enter Player Name"
            className="border p-3"
            type="text"
            onChange={(e) => setPlayer3(e.target.value)}
            required
          />
       <TextField
          id="filled-select-currency"
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category they will be participating in"
          onChange={ (e) => setPlayer3Type(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Control
            placeholder="Enter Player Name"
            className="border p-3"
            type="text"
            onChange={(e) => setPlayer4(e.target.value)}
            required
          />
       <TextField
          id="filled-select-currency"
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category they will be participating in"
          onChange={ (e) => setPlayer4Type(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Control
            placeholder="Enter Player Name"
            className="border p-3"
            type="text"
            onChange={(e) => setPlayer5(e.target.value)}
            required
          />
       <TextField
          fullWidth
          select
          label="Select"
          defaultValue={'Singles Category'}
          helperText="Please select the Singles category they will be participating in"
          onChange={ (e) => setPlayer5Type(e.target.value)}
          required
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {player1 !== '' && player2 !== '' && player3 !== '' && player4 !== '' && player5 !== '' && playerCaptain !== ''  && (
      <div>
         <Form.Label>Players (Doubles Category)</Form.Label>
         <Form.Label>Doubles Team 1</Form.Label>
          <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected1(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Label>Doubles Team 2</Form.Label>
        <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected2(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected3(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Form.Label>Doubles Team 3</Form.Label>
        <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected4(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="Select"
          onChange={ (e) => setDoublesListSelected5(e.target.value)}
          required
        >
          {doublesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
  ) }
        </Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3"
            type="email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3"
            type="password"
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Sign Up
        </Button>
      </Form>
      <p className="py-2">
        Already registered?{" "}
        <Link to="/" className="underline">
          Sign in.
        </Link>
      </p>
    </div>
  );
};

export default Signup;
