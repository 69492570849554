import {React, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { v4 as uuid } from 'uuid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import { getFirestore, getDocs, collection, where, query, addDoc, doc, getDoc } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button, Hidden,
} from "@mui/material";

export default function CheckboxesGroup() {
  
  const navigate = useNavigate();
  const db = getFirestore();
  const [error, setError] = useState("");
  const [UsersPlayers, setUsersPlayers] =  useState([])
  const [fixtureData, setFixturesData] = useState([])
  const [doublesFirstMatchWinner, setDoublesFirstMatchWinner] =  useState([])
  const [doublesSecondMatchWinner, setDoublesSecondMatchWinner] =  useState([])
  const [doublesThirdMatchWinner, setDoublesThirdMatchWinner] =  useState([])
  const [singlesFirstMatchWinner, setSinglesFirstMatchWinner] =  useState([])
  const [singlesSecondMatchWinner, setSinglesSecondMatchWinner] =  useState([])
  const [singlesThirdMatchWinner, setSinglesThirdMatchWinner] =  useState([])
  const [singlesFourthMatchWinner, setSinglesFourthMatchWinner] =  useState([])
  const [singlesFithMatchWinner, setSinglesFithMatchWinner] =  useState([])
  const [singlesSixthMatchWinner, setSinglesSixthMatchWinner] =  useState([])
  const [teamFirstMatchWinner, setTeamFirstMatchWinner] =  useState([])
  const [teamSecondMatchWinner, setTeamSecondMatchWinner] =  useState([])
  const [code, setCode] =  useState([])
  const overallwinner = [
    doublesFirstMatchWinner, 
    doublesSecondMatchWinner,
    doublesThirdMatchWinner,
    singlesFirstMatchWinner,
    singlesSecondMatchWinner,
    singlesThirdMatchWinner,
    singlesFourthMatchWinner,
    singlesFithMatchWinner,
    singlesSixthMatchWinner,
    teamFirstMatchWinner,
    teamSecondMatchWinner
  ];
  const [oPCode, setOPCode] = useState([])
  useEffect(() => {
    const getOpponentData = async () => {
      if (!localStorage.getItem("doublesOpponent")) {
        const querySnapshot = await getDocs(query(
          collection(db, "tempMatchData"),
          where("matchId", "==", localStorage.getItem('matchSelected')),
          where("teamName", "!=", localStorage.getItem("User"))
        ));
  
        await querySnapshot.forEach((doc) => {
          setUsersPlayers(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
        });
  
        const docRef = doc(db, 'fixtures', localStorage.getItem('matchSelected'));
        const docSnapshot = await getDoc(docRef);
        const fixtureData = docSnapshot.data();
        setFixturesData(fixtureData);
      } else {
        setUsersPlayers(JSON.parse(localStorage.getItem("doublesOpponent")));
      }
    };
  
    getOpponentData();
  }, []);
  
  useEffect(() => {
    const getOpponentCode = async () => {
      const teamNames = UsersPlayers.map((player) => player.teamName);
      const querySnapshotCode = await getDocs(query(
        collection(db, "captains"),
        where("teamName", "in", teamNames)
      ));
  
      setCode(querySnapshotCode.docs.map((doc) => ({ ...doc.data() })));
    };

  
    if (UsersPlayers.length > 0 && !localStorage.getItem("doublesOpponent")) {
      localStorage.setItem("doublesOpponent", JSON.stringify(UsersPlayers));
    }
  
    getOpponentCode();
  }, [UsersPlayers]);
  

  const teamOne = localStorage.getItem("User")
  const teamTwo = UsersPlayers[0]?.teamName
  var count = 0;
  var secondCount = 0;

      for (var i = 0; i < overallwinner.length; i++){
          if(overallwinner[i].team === teamOne){
              count++;
          }else if(overallwinner[i].team === teamTwo){
            secondCount++;
          }
      }

      // useEffect(() => {
      //   const getOpponent = async () => {
      //     if (!localStorage.getItem("doublesMatch")) {
      //       const querySnapshot = await getDocs(query(
      //         collection(db, "tempMatchData"),
      //         where("matchId", "==", localStorage.getItem('matchSelected')),
      //         where("teamName", "==", localStorage.getItem("User"))
      //       ));
      
      //       await querySnapshot.forEach((doc) => {
      //         setSignedInTeam(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
      //       });
      
      //       const docRef = doc(db, 'fixtures', localStorage.getItem('matchSelected'));
      //       const docSnapshot = await getDoc(docRef);
      //       const fixtureData = docSnapshot.data();
      //       setFixturesData(fixtureData);
      //     } else {
      //       setUsersPlayers(JSON.parse(localStorage.getItem("doublesOpponent")));
      //     }
      //   };

      
      //   getOpponent();
      // }, [UsersPlayers]);
const signedInTeam = JSON.parse(localStorage.getItem("doublesMatch"))
const [gameWinner, setGameWinner] = useState([])
      const points = [{
        teamOne: teamOne,
        clubOne: teamOne === localStorage.getItem('User')
        ? signedInTeam.doublesList[0]?.clubName
        : UsersPlayers[0]?.club,
        teamOnePoints: count,
        datePoints: localStorage.getItem('matchDate'),
        teamTwo: teamTwo,
        clubTwo: teamTwo === localStorage.getItem('User')
        ? signedInTeam.doublesList[0]?.clubName
        : UsersPlayers[0]?.club,
        teamTwoPoints: secondCount
      }]
      useEffect(() => {
        if(points[0]?.teamOnePoints >= 6 ){
          setGameWinner({winner: points[0]?.teamOne,
          club: points[0]?.teamOne === localStorage.getItem('User')
          ? signedInTeam.doublesList[0]?.clubName
          : UsersPlayers[0]?.club,
          })

        }else if(points[0]?.teamTwoPoints >= 6){
          setGameWinner({winner: points[0]?.teamTwo,
            club: points[0]?.teamOne === localStorage.getItem('User')
          ? signedInTeam.doublesList[0]?.clubName
          : UsersPlayers[0]?.club,
          })

        }else{
          setGameWinner({winner: "no winner yet"})
        }
      }, [points]);
  const unique_id = uuid();
  const small_id = unique_id.slice(0,8)
  const [loading, setLoading ] = useState(true);
  const [hometeam, setHomeTeam] = useState(true);
  const [playTime, setPlayTime] = useState(true);
  const [opponentDataLoaded, setOpponentDataLoaded] = useState(true);
  useEffect(() => {
    if(fixtureData.home === localStorage.getItem("User")){
      setHomeTeam(true)
    }else{
      setHomeTeam(false)
    }
    const dateCurrent = new Date();
    const matchDate = new Date(localStorage.getItem('matchDate'));
    if (dateCurrent >= matchDate) {
      setPlayTime(true)
    } else {
      setPlayTime(false)
    }
    if(UsersPlayers.length > 0 && (localStorage.getItem('doublesOpponent') !== null || localStorage.getItem('doublesOpponent').length > 0)){
      setOpponentDataLoaded(true)
    }else{
      setOpponentDataLoaded(false)
    }
      setTimeout(()=>setLoading(false), 3000);
  }, [UsersPlayers, fixtureData, signedInTeam]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(oPCode !== code[0].uniqueCode){
        toast.error('Opponent Code entered does not match', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }else{
        await addDoc(collection(db, 'match'), {
          matchId: localStorage.getItem('matchSelected'),
          doublesWinners: [doublesFirstMatchWinner, doublesSecondMatchWinner, doublesThirdMatchWinner, doublesMatch1LosersValue, doublesMatch2LosersValue, doublesMatch3LosersValue],
          singlesWinners: [singlesFirstMatchWinner, singlesSecondMatchWinner, singlesThirdMatchWinner, singlesFourthMatchWinner, singlesFithMatchWinner, singlesSixthMatchWinner, singlesMatch1LosersValue, singlesMatch2LosersValue, singlesMatch3LosersValue, singlesMatch4LosersValue, singlesMatch5LosersValue, singlesMatch6LosersValue],
          teamGamesWinners: [teamFirstMatchWinner, teamSecondMatchWinner],
          teamGamesLosers: [teamsMatch1LosersValue, teamsMatch2LosersValue],
          matchWinner: gameWinner,
          pointsWon: points,
          achievements: inputFields,
          datePlayed: localStorage.getItem('matchDate')

      })
      localStorage.removeItem("doublesOpponent");
      localStorage.removeItem("doubles");
      localStorage.removeItem("doublesMatch");
      localStorage.removeItem("matchSelected");
      navigate("/account");
      }
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  
  }
const dropdownValues = [
  {
    otherValues: [{name: 'Select'},{name: signedInTeam.doublesIndexData[0].name}, {name: signedInTeam.doublesIndexData[1].name}, {name: signedInTeam.doublesIndexData2[0].name},  {name: signedInTeam.doublesIndexData2[1].name},  {name:  signedInTeam.doublesIndexData3[0].name},  {name: signedInTeam.doublesIndexData3[1].name}, {name: UsersPlayers[0]?.players.doublesIndexData[0].name}, {name: UsersPlayers[0]?.players.doublesIndexData[1].name}, {name: UsersPlayers[0]?.players.doublesIndexData2[0].name}, {name: UsersPlayers[0]?.players.doublesIndexData2[1].name}, {name: UsersPlayers[0]?.players.doublesIndexData3[0].name}, {name: UsersPlayers[0]?.players.doublesIndexData3[1].name}]
  }
]
const [highCloses, setHighCloses] = useState({
  highClose: '',
});
  const [inputFields, setInputFields] = useState([{
    id: small_id,
    oneSevenOnes:'',
    oneEighty: '',
    closed: [{closed:""}],
    fullName: ''
} ]);
// const addHighClose = ()=>{
//   setHighCloses([...highCloses, {
//     highClose: '',
//     fullName: ''   
//   } ])

// }
//console.log(inputFields)
const addInputField = ()=>{
    setInputFields([...inputFields, {
        oneSevenOnes:'',
        oneEighty: '',
        closed: [{closed:""}],
        fullName: ''
        
    } ])
  
}
const addHighClose = (listCloseIndex) => {
  setInputFields(inputFields => inputFields.map((item, index) =>
  index === listCloseIndex ? ({
    ...item,
    closed: [...item.closed, {test:""}]
  }) : item
))
}

const removeInputFields = (index)=>{
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
}
const handleChange = (index, evnt)=>{

const { name, value } = evnt.target;
const list = [...inputFields];
list[index][name] = value;
setInputFields(list);

}
const removeHighCloses = (index, listCloseIndex)=>{
  const rowsHighClose = [...inputFields];
  rowsHighClose[listCloseIndex].closed.splice(index, 1);
  setInputFields(rowsHighClose);
}
const handleHighCloseChange = (index, evnt, listCloseIndex)=>{
  const name = evnt.target.name;
  const value = evnt.target.value;
  const listClose = [...inputFields];
  listClose[listCloseIndex].closed[index][name] = value;
  setInputFields(listClose);
  }
  const matchDate = localStorage.getItem("matchDate");
  const [date, time] = matchDate.split(", ");
  const [year, month, day] = date.split("/");
  const [hours, minutes, seconds] = time.split(":");
  const targetDate = new Date(year, month - 1, day, hours, minutes, seconds);
  const currentDate = new Date().getTime();
  const [selectedDoublesMatch1Value, setselectedDoublesMatch1Value] = useState('');
  const [selectedDoublesMatch2Value, setselectedDoublesMatch2Value] = useState('');
  const [selectedDoublesMatch3Value, setselectedDoublesMatch3Value] = useState('');
  const [selectedSinglesMatch1Value, setselectedSinglesMatch1Value] = useState('');
  const [selectedSinglesMatch2Value, setselectedSinglesMatch2Value] = useState('');
  const [selectedSinglesMatch3Value, setselectedSinglesMatch3Value] = useState('');
  const [selectedSinglesMatch4Value, setselectedSinglesMatch4Value] = useState('');
  const [selectedSinglesMatch5Value, setselectedSinglesMatch5Value] = useState('');
  const [selectedSinglesMatch6Value, setselectedSinglesMatch6Value] = useState('');
  const [selectedTeamsMatch1Value, setselectedTeamsMatch1Value] = useState('');
  const [selectedTeamsMatch2Value, setselectedTeamsMatch2Value] = useState('');
  const [doublesMatch1LosersValue, setdoublesMatch1LosersValue] = useState({
    player1: '',
    doublesPartner: '',
    type: '',
    reserve: '',
    team: ''
  });
  const [doublesMatch2LosersValue, setdoublesMatch2LosersValue] = useState({
    player1: '',
    doublesPartner: '',
    reserve: '',
    type: '',
    team: ''
  });
  
  const [doublesMatch3LosersValue, setdoublesMatch3LosersValue] = useState({
    player1: '',
    doublesPartner: '',
    reserve: '',
    type: '',
    team: ''
  });

  const [singlesMatch1LosersValue, setsinglesMatch1LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [singlesMatch2LosersValue, setsinglesMatch2LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [singlesMatch3LosersValue, setsinglesMatch3LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [singlesMatch4LosersValue, setsinglesMatch4LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [singlesMatch5LosersValue, setsinglesMatch5LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [singlesMatch6LosersValue, setsinglesMatch6LosersValue] = useState({
    loser: '',
    type: '',
    reserve: '',
    team: ''
  });

  const [teamsMatch1LosersValue, setteamsMatch1LosersValue] = useState({
    loser: '',
    team: ''
  });
  const [teamsMatch2LosersValue, setteamsMatch2LosersValue] = useState({
    loser: '',
    team: ''
  });
  const doubles1RadioChange = (event) => {
    const selectedDoublesMatch1 = event.target.value;
    setselectedDoublesMatch1Value(selectedDoublesMatch1);
  
    const doublesMatch1Losers = selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
      ? {
          player1: UsersPlayers[0]?.players.doublesIndexData[0].name,
          doublesPartner: UsersPlayers[0]?.players.doublesIndexData[1].name,
          type: UsersPlayers[0]?.players.doublesIndexData[0].type,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName,
          player1Reserve: UsersPlayers[0]?.players.doublesIndexData[0].reserve || 'no',
          doublesPartnerReserve: UsersPlayers[0]?.players.doublesIndexData[1].reserve || 'no',
          clubName: UsersPlayers[0]?.club
        }
      : {
          player1: signedInTeam.doublesIndexData[0].name,
          doublesPartner: signedInTeam.doublesIndexData[1].name,
          clubName: signedInTeam.doublesList[0]?.clubName,
          player1Reserve:  signedInTeam.doublesIndexData[0].reserve || 'no',
          doublesPartnerReserve:  signedInTeam.doublesIndexData[1].reserve || 'no',
          result: 'Lost',
          type: signedInTeam.doublesIndexData[0].type,
          team: localStorage.getItem("User")
        };
  
    setdoublesMatch1LosersValue(doublesMatch1Losers);

    // Update the setDoublesSecondMatchWinner state with the selectedDoublesMatch1 value
    setDoublesFirstMatchWinner({
      player1: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[0].name
        : UsersPlayers[0]?.players.doublesIndexData[0].name,
        player1Reserve: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[0].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData[0].reserve || 'no',
      doublesPartner: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[1].name
        : UsersPlayers[0]?.players.doublesIndexData[1].name,
        doublesPartnerReserve: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[1].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData[1].reserve || 'no',
      type: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[0].type
        : UsersPlayers[0]?.players.doublesIndexData[0].type,
      team: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
      clubName: selectedDoublesMatch1 === signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name
      ? signedInTeam.doublesList[0]?.clubName
      : UsersPlayers[0]?.club,
      result: "Winner",
    });
  
    // Additional code for handling the selectedDoublesMatch1 and doublesMatch1Losers values...
  };
  const doubles2RadioChange = (event) => {
    const selectedDoublesMatch2 = event.target.value;
    setselectedDoublesMatch2Value(selectedDoublesMatch2);
  
    const doublesMatch2Losers = selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
      ? {
          player1: UsersPlayers[0]?.players.doublesIndexData2[0].name,
          doublesPartner: UsersPlayers[0]?.players.doublesIndexData2[1].name,
          player1Reserve: UsersPlayers[0]?.players.doublesIndexData2[0].reserve || 'no',
          doublesPartnerReserve: UsersPlayers[0]?.players.doublesIndexData2[1].reserve || 'no',
          type: UsersPlayers[0]?.players.doublesIndexData2[0].type,
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          player1: signedInTeam.doublesIndexData2[0].name,
          doublesPartner: signedInTeam.doublesIndexData2[1].name || 'no',
          player1Reserve:  signedInTeam.doublesIndexData2[0].reserve || 'no',
          doublesPartnerReserve:  signedInTeam.doublesIndexData2[1].reserve,
          type: signedInTeam.doublesIndexData2[0].type,
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setdoublesMatch2LosersValue(doublesMatch2Losers);
  
    // Update the setDoublesSecondMatchWinner state with the selectedDoublesMatch2 value
    setDoublesSecondMatchWinner({
      player1: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[0].name
        : UsersPlayers[0]?.players.doublesIndexData2[0].name,
        player1Reserve: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[0].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData2[0].reserve || 'no',
      doublesPartner: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[1].name
        : UsersPlayers[0]?.players.doublesIndexData2[1].name,
        doublesPartnerReserve: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[1].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData2[1].reserve || 'no',
      type: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[0].type
        : UsersPlayers[0]?.players.doublesIndexData2[0].type,
      team: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedDoublesMatch2 === signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesList[0]?.clubName
        : UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selectedDoublesMatch2 and doublesMatch2Losers values...
  };
  const doubles3RadioChange = (event) => {
    const selectedDoublesMatch3 = event.target.value;
    setselectedDoublesMatch3Value(selectedDoublesMatch3);
  
    const doublesMatch3Losers = selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
      ? {
          player1: UsersPlayers[0]?.players.doublesIndexData3[0].name,
          doublesPartner: UsersPlayers[0]?.players.doublesIndexData3[1].name,
          type: UsersPlayers[0]?.players.doublesIndexData3[0].type,
          player1Reserve: UsersPlayers[0]?.players.doublesIndexData3[0].reserve  || 'no',
          doublesPartnerReserve: UsersPlayers[0]?.players.doublesIndexData3[1].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          player1: signedInTeam.doublesIndexData3[0].name,
          doublesPartner: signedInTeam.doublesIndexData3[1].name,
          type: signedInTeam.doublesIndexData3[0].type,
          player1Reserve: signedInTeam.doublesIndexData3[0].reserve  || 'no',
          doublesPartnerReserve: signedInTeam.doublesIndexData3[1].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setdoublesMatch3LosersValue(doublesMatch3Losers);
    // Update the setDoublesSecondMatchWinner state with the selectedDoublesMatch3 value
    setDoublesThirdMatchWinner({
      player1: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[0].name
        : UsersPlayers[0]?.players.doublesIndexData3[0].name,
        player1Reserve: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[0].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData3[0].reserve || 'no',
      doublesPartner: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[1].name
        : UsersPlayers[0]?.players.doublesIndexData3[1].name,
        doublesPartnerReserve: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[1].reserve || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData3[1].reserve || 'no',
      type: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[0].type
        : UsersPlayers[0]?.players.doublesIndexData3[0].type,
      team: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedDoublesMatch3 === signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesList[0]?.clubName
        : UsersPlayers[0]?.club,
        result: 'Winner',
    });
    // Additional code for handling the selectedDoublesMatch3 and doublesMatch3Losers values...
  };
  const singles1RadioChange = (event) => {
    const selectedSinglesMatch1Value = event.target.value;
    setselectedSinglesMatch1Value(selectedSinglesMatch1Value);
  
    const unselected = selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData[0].name,
          type: UsersPlayers[0]?.players.doublesIndexData[0].singlesType,
          reserve: UsersPlayers[0]?.players.doublesIndexData[0].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData[0].name,
          type: signedInTeam.doublesIndexData[0].singlesType,
          reserve: signedInTeam.doublesIndexData[0].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch1LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesFirstMatchWinner({
      winner: selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
        ? signedInTeam.doublesIndexData[0].name
        : UsersPlayers[0]?.players.doublesIndexData[0].name,
        reserve: selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
        ? signedInTeam.doublesIndexData[0].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData[0].reserve  || 'no',
      type: selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
        ? signedInTeam.doublesIndexData[0].singlesType
        : UsersPlayers[0]?.players.doublesIndexData[0].singlesType,
      team: selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
      clubName: selectedSinglesMatch1Value === signedInTeam.doublesIndexData[0].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selected and unselected values...
  };
  const singles2RadioChange = (event) => {
    const selectedSinglesMatch2Value = event.target.value;
    setselectedSinglesMatch2Value(selectedSinglesMatch2Value);
  
    const unselected = selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData[1].name,
          type: UsersPlayers[0]?.players.doublesIndexData[1].singlesType,
          reserve: UsersPlayers[0]?.players.doublesIndexData[1].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData[1].name,
          type: signedInTeam.doublesIndexData[1].singlesType,
          reserve: signedInTeam.doublesIndexData[1].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch2LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesSecondMatchWinner({
      winner: selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[1].name
        : UsersPlayers[0]?.players.doublesIndexData[1].name,
      type: selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[1].singlesType
        : UsersPlayers[0]?.players.doublesIndexData[1].singlesType,
        reserve: selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
        ? signedInTeam.doublesIndexData[1].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData[1].reserve  || 'no',
      team: selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedSinglesMatch2Value === signedInTeam.doublesIndexData[1].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selected and unselected values...
  };
  const singles3RadioChange = (event) => {
    const selectedSinglesMatch3Value = event.target.value;
    setselectedSinglesMatch3Value(selectedSinglesMatch3Value);
  
    const unselected = selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData2[0].name,
          type: UsersPlayers[0]?.players.doublesIndexData2[0].singlesType,
          reserve: UsersPlayers[0]?.players.doublesIndexData2[0].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData2[0].name,
          type: signedInTeam.doublesIndexData2[0].singlesType,
          reserve: signedInTeam.doublesIndexData2[0].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch3LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesThirdMatchWinner({
      winner: selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
        ? signedInTeam.doublesIndexData2[0].name
        : UsersPlayers[0]?.players.doublesIndexData2[0].name,
      type: selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
        ? signedInTeam.doublesIndexData2[0].singlesType
        : UsersPlayers[0]?.players.doublesIndexData2[0].singlesType,
        reserve: selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
        ? signedInTeam.doublesIndexData2[0].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData2[0].reserve  || 'no',
      team: selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedSinglesMatch3Value === signedInTeam.doublesIndexData2[0].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selected and unselected values...
  };
  const singles4RadioChange = (event) => {
    const selectedSinglesMatch4Value = event.target.value;
    setselectedSinglesMatch4Value(selectedSinglesMatch4Value);
  
    const unselected = selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData2[1].name,
          type: UsersPlayers[0]?.players.doublesIndexData2[1].singlesType,
          reserve:  UsersPlayers[0]?.players.doublesIndexData2[1].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData2[1].name,
          type: signedInTeam.doublesIndexData2[1].singlesType,
          reserve: signedInTeam.doublesIndexData2[1].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch4LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesFourthMatchWinner({
      winner: selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[1].name
        : UsersPlayers[0]?.players.doublesIndexData2[1].name,
      type: selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[1].singlesType
        : UsersPlayers[0]?.players.doublesIndexData2[1].singlesType,
        reserve: selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
        ? signedInTeam.doublesIndexData2[1].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData2[1].reserve  || 'no',
      team: selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedSinglesMatch4Value === signedInTeam.doublesIndexData2[1].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selected and unselected values...
  };
  const singles5RadioChange = (event) => {
    const selectedSinglesMatch5Value = event.target.value;
    setselectedSinglesMatch5Value(selectedSinglesMatch5Value);
  
    const unselected = selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData3[0].name,
          type: UsersPlayers[0]?.players.doublesIndexData3[0].singlesType,
          reserve:  UsersPlayers[0]?.players.doublesIndexData3[0].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData3[0].name,
          type: signedInTeam.doublesIndexData3[0].singlesType,
          reserve: signedInTeam.doublesIndexData3[0].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch5LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesFithMatchWinner({
      winner: selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
        ? signedInTeam.doublesIndexData3[0].name
        : UsersPlayers[0]?.players.doublesIndexData3[0].name,
      type: selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
        ? signedInTeam.doublesIndexData3[0].singlesType
        : UsersPlayers[0]?.players.doublesIndexData3[0].singlesType,
        reserve: selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
        ? signedInTeam.doublesIndexData3[0].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData3[0].reserve  || 'no',
      team: selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedSinglesMatch5Value === signedInTeam.doublesIndexData3[0].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
    // Additional code for handling the selected and unselected values...
  };

  const singles6RadioChange = (event) => {
    const selectedSinglesMatch6Value = event.target.value;
    setselectedSinglesMatch6Value(selectedSinglesMatch6Value);
  
    const unselected = selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
      ? {
          loser: UsersPlayers[0]?.players.doublesIndexData3[1].name,
          type: UsersPlayers[0]?.players.doublesIndexData3[1].singlesType,
          reserve: UsersPlayers[0]?.players.doublesIndexData3[1].reserve  || 'no',
          clubName: UsersPlayers[0]?.club,
          result: 'Lost',
          team: UsersPlayers[0]?.teamName
        }
      : {
          loser: signedInTeam.doublesIndexData3[1].name,
          type: signedInTeam.doublesIndexData3[1].singlesType,
          reserve: signedInTeam.doublesIndexData3[1].reserve  || 'no',
          clubName: signedInTeam.doublesList[0]?.clubName,
          result: 'Lost',
          team: localStorage.getItem("User")
        };
  
    setsinglesMatch6LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setSinglesSixthMatchWinner({
      winner: selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[1].name
        : UsersPlayers[0]?.players.doublesIndexData3[1].name,
      type: selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[1].singlesType
        : UsersPlayers[0]?.players.doublesIndexData3[1].singlesType,
        reserve: selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
        ? signedInTeam.doublesIndexData3[1].reserve  || 'no'
        :  UsersPlayers[0]?.players.doublesIndexData3[1].reserve  || 'no',
      team: selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
        ? localStorage.getItem("User")
        : UsersPlayers[0]?.teamName,
        clubName: selectedSinglesMatch6Value === signedInTeam.doublesIndexData3[1].name
        ?  signedInTeam.doublesList[0]?.clubName
        :  UsersPlayers[0]?.club,
        result: 'Winner',
    });
  
  };

  const teams1RadioChange = (event) => {
    const selectedTeamsMatch1Value = event.target.value;
    setselectedTeamsMatch1Value(selectedTeamsMatch1Value);
  
    const unselected = selectedTeamsMatch1Value === localStorage.getItem("User")
    ? {
      loser: UsersPlayers[0]?.teamName,
      clubName: UsersPlayers[0]?.club,
      date: localStorage.getItem('matchDate'),
      team: UsersPlayers[0]?.teamName
    }
  : {
      loser: localStorage.getItem("User"),
      clubName: signedInTeam.doublesList[0]?.clubName,
      date: localStorage.getItem('matchDate'),
      team: localStorage.getItem("User")
    };
  
    setteamsMatch1LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setTeamFirstMatchWinner({
      winner: selectedTeamsMatch1Value,
      clubName: selectedTeamsMatch1Value === localStorage.getItem("User")
      ? UsersPlayers[0]?.club
      : signedInTeam.doublesList[0]?.clubName,
      date: localStorage.getItem('matchDate'),
      loser: selectedTeamsMatch1Value === localStorage.getItem("User")
      ? UsersPlayers[0]?.teamName
      : localStorage.getItem("User"),
      team: selectedTeamsMatch1Value,
    });
  
    // Additional code for handling the selected and unselected values...
  };


  //
  const teams2RadioChange = (event) => {
    const selectedTeamsMatch2Value = event.target.value;
    setselectedTeamsMatch2Value(selectedTeamsMatch2Value);
  
    const unselected = selectedTeamsMatch2Value === localStorage.getItem("User")
    ? {
      loser: UsersPlayers[0]?.teamName,
      clubName: UsersPlayers[0]?.club,
      date: localStorage.getItem('matchDate'),
      team: UsersPlayers[0]?.teamName
    }
  : {
      loser: localStorage.getItem("User"),
      clubName: signedInTeam.doublesList[0]?.clubName,
      date: localStorage.getItem('matchDate'),
      team: localStorage.getItem("User")
    };
  
    setteamsMatch2LosersValue(unselected);
  
    // Update the setSinglesFirstMatchWinner state with the selected value
    setTeamSecondMatchWinner({
      winner: selectedTeamsMatch2Value,
      clubName: selectedTeamsMatch2Value === localStorage.getItem("User")
      ? UsersPlayers[0]?.club
      : signedInTeam.doublesList[0]?.clubName,
      date: localStorage.getItem('matchDate'),
      loser: selectedTeamsMatch2Value === localStorage.getItem("User")
      ? UsersPlayers[0]?.teamName
      : localStorage.getItem("User"),
      team: selectedTeamsMatch2Value,
    });
  
    // Additional code for handling the selected and unselected values...
  };
  return (
    <>
 <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    {/* {currentDate > targetDate ? ( */}
    {!loading ? (
      <>
            {opponentDataLoaded ? (
              <>
              {playTime ? (
                <>
                 {!hometeam ? (
                    <div style={{textAlign: 'center', marginTop: "30%", marginBottom: "50%", width: '100vw'}}>
                    <h3>{localStorage.getItem('User')} VS {UsersPlayers[0]?.teamName}</h3>
                    <hr/>
                    <h5>Please fill in the form below before submitting match results</h5>
                      <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Doubles Match: 1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 1st Doubles Match</p>
                      <hr/>
                  <Box>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedDoublesMatch1Value}
                        onChange={doubles1RadioChange}
                      >
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name}
                          control={<Radio />}
                          label={signedInTeam.doublesIndexData[0].name + ' & ' + signedInTeam.doublesIndexData[1].name}
                        />
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={UsersPlayers[0]?.players.doublesIndexData[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData[1].name}
                          control={<Radio />}
                          label={UsersPlayers[0]?.players.doublesIndexData[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData[1].name}
                        />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                  </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Doubles Match: 2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p style={{textAlign: 'center'}}>Please select the winner of the 2nd Doubles Match</p>
                        <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedDoublesMatch2Value}
                        onChange={doubles2RadioChange}
                      >
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name}
                          control={<Radio />}
                          label={signedInTeam.doublesIndexData2[0].name + ' & ' + signedInTeam.doublesIndexData2[1].name}
                        />
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={UsersPlayers[0]?.players.doublesIndexData2[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData2[1].name}
                          control={<Radio />}
                          label={UsersPlayers[0]?.players.doublesIndexData2[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData2[1].name}
                        />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <Typography>Doubles Match: 3</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 3rd Doubles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedDoublesMatch3Value}
                        onChange={doubles3RadioChange}
                      >
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name}
                          control={<Radio />}
                          label={signedInTeam.doublesIndexData3[0].name + ' & ' + signedInTeam.doublesIndexData3[1].name}
                        />
                        <FormControlLabel
                          style={{ whiteSpace: 'break-spaces', textAlign: 'initial' }}
                          value={UsersPlayers[0]?.players.doublesIndexData3[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData3[1].name}
                          control={<Radio />}
                          label={UsersPlayers[0]?.players.doublesIndexData3[0].name + ' & ' + UsersPlayers[0]?.players.doublesIndexData3[1].name}
                        />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                      >
                        <Typography>Singles Match: 1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 1st Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch1Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles1RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData[0].name} control={<Radio />} label={signedInTeam.doublesIndexData[0].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData[0].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData[0].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                      >
                        <Typography>Singles Match: 2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 2nd Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch2Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles2RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData[1].name} control={<Radio />} label={signedInTeam.doublesIndexData[1].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData[1].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData[1].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6a-content"
                        id="panel6a-header"
                      >
                        <Typography>Singles Match: 3</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 3rd Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch3Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles3RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData2[0].name} control={<Radio />} label={signedInTeam.doublesIndexData2[0].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData2[0].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData2[0].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7a-content"
                        id="panel7a-header"
                      >
                        <Typography>Singles Match: 4</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 4th Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch4Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles4RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData2[1].name} control={<Radio />} label={signedInTeam.doublesIndexData2[1].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData2[1].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData2[1].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8a-content"
                        id="panel8a-header"
                      >
                        <Typography>Singles Match: 5</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 5th Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch5Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles5RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData3[0].name} control={<Radio />} label={signedInTeam.doublesIndexData3[0].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData3[0].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData3[0].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9a-content"
                        id="panel9a-header"
                      >
                        <Typography>Singles Match: 6</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 6th Singles Match</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedSinglesMatch6Value}  // Add selectedSinglesValue as the value prop
                        onChange={singles6RadioChange}  // Update the onClick event to onChange event and use handleSinglesRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={signedInTeam.doublesIndexData3[1].name} control={<Radio />} label={signedInTeam.doublesIndexData3[1].name} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.players.doublesIndexData3[1].name} control={<Radio />} label={UsersPlayers[0]?.players.doublesIndexData3[1].name} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px' , marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel10a-content"
                        id="panel10a-header"
                      >
                        <Typography>Team Games: 1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 1st Team Game</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedTeamsMatch1Value}  // Add selectedTeamValue as the value prop
                        onChange={teams1RadioChange}  // Update the onClick event to onChange event and use handleTeamRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={localStorage.getItem("User")} control={<Radio />} label={localStorage.getItem("User")} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.teamName} control={<Radio />} label={UsersPlayers[0]?.teamName} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{paddingTop: '16px', paddingBottom: '16px', marginTop: '16px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel11a-content"
                        id="panel11a-header"
                      >
                        <Typography>Team Games: 2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <p style={{textAlign: 'center'}}>Please select the winner of the 2nd Team Game</p>
                      <hr/>
                      <Box>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedTeamsMatch2Value}  // Add selectedTeamValue as the value prop
                        onChange={teams2RadioChange}  // Update the onClick event to onChange event and use handleTeamRadioChange function
                      >
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={localStorage.getItem("User")} control={<Radio />} label={localStorage.getItem("User")} />
                        <FormControlLabel style={{whiteSpace: 'break-spaces', textAlign: 'initial'}} value={UsersPlayers[0]?.teamName} control={<Radio />} label={UsersPlayers[0]?.teamName} />
                      </RadioGroup>
                    }
                  />
                </FormGroup>
              </FormControl>
                    </Box>
                      </AccordionDetails>
                    </Accordion>
                  <div className="container">
                          <div className="row">
                              <div className="col-sm-8">
                                <h5 style={{paddingTop: "10px"}}>Player 180's, 171's & HighCloses:</h5>
                                {
                                    inputFields.map((data, listCloseIndex)=>{
                                        return(
                                          <div className="row my-3" key={listCloseIndex}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select Player Name"
                        name="fullName"
                        value={data.fullName}
                        defaultValue={dropdownValues[0].otherValues[0].name}
                        helperText="Player Info"
                        onChange={(evnt)=>handleChange(listCloseIndex, evnt)}
                      >
                        {dropdownValues[0].otherValues.map((option) => (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                                  <div style={{width: "100%"}}>
                                  <div className="form-group">
                                  <input type="text" onChange={(evnt)=>handleChange(listCloseIndex, evnt)} value={data.oneSevenOnes} name="oneSevenOnes" className="form-control"  placeholder="171's" style={{marginBottom: "10px"}}/>
                                  <input type="text" onChange={(evnt)=>handleChange(listCloseIndex, evnt)} value={data.oneEighty} name="oneEighty" className="form-control"  placeholder="180's" style={{marginBottom: "10px"}}/>
                                  <button className="btn btn-outline-success " onClick={() => addHighClose(listCloseIndex)} style={{width: "100%", marginBottom: "10px", backgroundColor: "green", color: "white"}}>Add Closes</button>
                                  {data.closed.map((datas, t) => {
                                    return(
                                      <div>
                           <input type="text" onChange={(evnt)=>handleHighCloseChange(t, evnt, listCloseIndex)} value={datas.test} name="test" className="form-control"  placeholder="HighCloses" style={{marginBottom: "10px"}}/>
                            {(datas.length!==1)? <button className="btn btn-outline-danger" onClick={() => removeHighCloses(t, listCloseIndex)} style={{width: "100%", marginBottom: "10px", backgroundColor: "red", color: "white"}}>Delete Close Shown Above</button>:''}
                           </div>
                           ) })}
                                  </div>
                                  </div>
                                  <div className="col">
                               {(inputFields.length!==1)? <button className="btn btn-outline-danger" onClick={() => removeInputFields(listCloseIndex)} style={{width: "100%", marginBottom: "10px"}}>Delete Player Info Above</button>:''}
                               <hr></hr>
                                  </div>
                                </div>
                                        )
                                    })
                                }
              
                              <div className="row">
                                  <div className="col-sm-12">
                                  <button className="btn btn-outline-success " onClick={addInputField} style={{width: "100%", marginBottom: "10px", backgroundColor: "green", color: "white"}}>Add Another Player</button>
                                  <hr></hr>
                                  </div>
                              </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
               <Box
                    sx={{
                      '& > :not(style)': { width: '100%', marginTop: "16px" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField onChange={(e) => setOPCode(e.target.value)} id="outlined-basic" label="Opponent Code" variant="outlined" type="password"
                    helperText="Opponent needs to enter their code before being able to submit" />
                  </Box>
                              <button className="btn btn-outline-success " onClick={handleSubmit} style={{width: "100%", marginBottom: "10px"}}>Complete Match</button>
                              </div>
                          </div>
                    </div>

              ) : (
                <div style={{ overflowX: "Hidden"}}>
                   <text> Hello...Looks like your team is "Away" for this match, you can return to the Home screen and confirm the score sheet is correct with the Home Team once game is completed</text>
              <br/>
              <Button
              variant="contained"
              color="secondary"
              style={{backgroundColor: "#2C4945", width: "100%", marginTop: "10%"}}
              href="/account"
              sx={{ marginRight: 8 }}
            >
              Back to home
            </Button>
                </div>
              )}
                </>
              ):(
                <>
                <text>Please wait untill 19:00 pm on match day for match to start</text>
                </>
              )}
              </>
            ) : (
              <>
                <text>Please wait for your opponent to finish adding their order before game can begin</text>
              </>
            )}
            </>
      ):(
        <>
 <text> ...Loading, please wait</text>
        </>
      )}
      </>
  );
}