import React from "react";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Account from "./components/Account";
import Profile from "./components/Profile";
import Leaderboard from "./components/Leaderboard";
import Order from "./components/Order";
import Fixtures from "./components/Fixtures";
import Match from "./components/Match";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Issues on routing:
// - Profile is an unprotected view (system flashes the correct view and then returns a blank page if user is not logged in)

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <ToastContainer />
      <div>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route path="/update-profile" element={<Profile />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/fixtures" element={<Fixtures />} />
            <Route path="/match" element={<Match />} />
            <Route path="/order-of-players" element={<Order />} />
          </Routes>
        </AuthContextProvider>
      </div>
    </Container>
  );
}

export default App;
