
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {React, useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { UserAuth } from '../context/AuthContext';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rankings from './images/awards.png';
import fixtures from './images/calendar.png';


export default function EnhancedTable() {
    const { user, logout } = UserAuth();
    const navigate = useNavigate();
localStorage.setItem("User", user.displayName)

    const handleLogout = async () => {
      try {
        await logout();
        navigate('/');
        console.log('You are logged out')
      } catch (e) {
        console.log(e.message);
      }
    };

  return (
    <div>
      <ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
  minBreakpoint="xxs"
>
  <div>
            <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
     <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#home">CDA Social League</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" >
              <Nav.Link onClick={handleLogout}>Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    <Box sx={{ width: '100%', marginTop: 10, height: "100%", marginBottom: "5%" }}>
      <Card style={{ width: '18rem', marginBottom: "3%" }}>
      <Card.Img variant="top" src={rankings} />
      <Card.Body>
        <Card.Title>Leader Board</Card.Title>
        <Card.Text>
        </Card.Text>
        <Button variant="primary" href="/leaderboard">Go to Stats</Button>
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' }}>
    <Card.Img variant="top" src={fixtures} />
      <Card.Body>
        <Card.Title>Fixtures</Card.Title>
        <Card.Text>
        </Card.Text>
        <Button variant="primary" href="/fixtures">Go to Fixtures</Button>
      </Card.Body>
    </Card>
    </Box>
    </div>
</ThemeProvider>
    </div>
  );
}
