import {React, useState, useEffect, useCallback} from "react";
import Container from "react-bootstrap/Container";
import { render } from "react-dom";
import { SortableList } from "./NestedSortable/SortableList";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { getFirestore, getDocs, collection, where, query, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { UserAuth } from "../context/AuthContext";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Input } from "reactstrap";

export default function CustomizedAccordions() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { user } = UserAuth();
  const [doublesList, setDoublesList] = useState([]);
  const [playerCheck, setPlayerCheck] = useState([]);
  const [hasTempData, setTempData] = useState([]);
  const [doublesIndexData, setDoublesIndex1] = useState([]);
  const [doublesIndexData2, setDoublesIndex2] = useState([]);
  const [doublesIndexData3, setDoublesIndex3] = useState([]);
  const db = getFirestore();
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
  
  
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    await addDoc(collection(db, 'tempMatchData'), {
        teamName: auth.currentUser.displayName,
        club: doublesList[0].clubName,
        players: {doublesIndexData, doublesIndexData2, doublesIndexData3 },
        matchId: localStorage.getItem('matchSelected')
    });
    localStorage.setItem("doublesMatch",JSON.stringify({doublesIndexData, doublesIndexData2, doublesIndexData3, doublesList}))
    navigate("/match");
  } catch (e) {
    setError(e.message);
  }

}
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({});
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleChange = (event) => {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    };
    useEffect(() => {
      const getData = async () => {
        const doublesData = localStorage.getItem("doubles");
        const doublesCheckData = localStorage.getItem("doublescheck");
    
        if ((doublesData !== null && doublesList.length !== 0) || doublesCheckData !== null) {
          setDoublesList(JSON.parse(doublesData));
        } else {
          const querySnapshot = await getDocs(
            query(
              collection(db, "doubles"),
              where("teamName", "==", localStorage.getItem("User"))
            )
          );
    
          const newDoublesList = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            type: doc.data.doublesType,
            players: [
              {
                id: doc.id + 1,
                name: doc.data().player1,
                type: doc.data().doublesType,
                singlesType: doc.data().player1SinglesType,
              },
              {
                id: doc.id + 2,
                name: doc.data().player2,
                type: doc.data().doublesType,
                singlesType: doc.data().player2SinglesType,
              },
            ],
          }));
    
          setDoublesList(newDoublesList);
          setPlayerCheck(newDoublesList);
        }
        const querySnapshotCheck = await getDocs(query(collection(db, "tempMatchData"), where("matchId", "==", localStorage.getItem('matchSelected')), where("teamName", "==" , localStorage.getItem("User"))));
        await querySnapshotCheck.forEach((doc) => {
         setTempData(querySnapshotCheck.docs.map((doc) => ({ ...doc.data()})));
        });
      };
    
      getData();
    }, []);
    useEffect(() => {
      localStorage.setItem("doubles", JSON.stringify(doublesList));
      localStorage.setItem("playersCheck", JSON.stringify(playerCheck));
    }, [doublesList]);
    
    useEffect(() => {
      setDoublesIndex1(doublesList[0]?.players);
      setDoublesIndex2(doublesList[1]?.players);
      setDoublesIndex3(doublesList[2]?.players);
    }, [doublesList]);
    
    console.log(doublesIndexData)
    const handlePlayerNameChange = useCallback((index, field, value) => {
      setDoublesList((prevList) => {
        const updatedList = prevList.map((player, i) => {
          if (i === index) {
            const updatedPlayer = {
              ...player,
              [field]: value,
              players: player.players.map((p) => {
                const playerCheckData = playerCheck.find((pc) => pc.id === player.id);
                if (p.name === player[field]) {
                  return {
                    ...p,
                    name: value,
                    singlesType: player[field + 'SinglesType'],
                    reserve: playerCheckData && playerCheckData.players.some((pcp) => pcp.name !== value) ? 'yes' : p.reserve,
                  };
                }
                return p;
              }),
            };
            return updatedPlayer;
          }
          return player;
        });
        return updatedList;
      });
    }, [playerCheck]);
    const handlePlayerSinglesTypeChange = useCallback((index, playerId, value) => {
      setDoublesList((prevList) => {
        const updatedList = prevList.map((player, i) => {
          if (i === index) {
            const updatedPlayer = {
              ...player,
              players: player.players.map((p) => {
                if (p.id === playerId) {
                  return { ...p, singlesType: value };
                }
                return p;
              }),
            };
    
            // Determine the type based on the singlesType of both players
            const singlesType1 = updatedPlayer.players[0].singlesType;
            const singlesType2 = updatedPlayer.players[1].singlesType;
    
            if (singlesType1 === 'Mens' && singlesType2 === 'Mens') {
              updatedPlayer.players[0].type  = 'mens Doubles';
              updatedPlayer.players[1].type  = 'mens Doubles';
            } else if (singlesType1 === 'Ladies' && singlesType2 === 'Ladies') {
              updatedPlayer.players[0].type = 'ladies Doubles';
              updatedPlayer.players[1].type = 'ladies Doubles';
            } else {
              updatedPlayer.players[0].type = 'mixed Doubles';
              updatedPlayer.players[1].type = 'mixed Doubles';
            }
    
            return updatedPlayer;
          }
          return player;
        });
        return updatedList;
      });
    }, []);
    
    
    
 
        const [loading, setLoading ] = useState(true);
        useEffect(() => {
            setTimeout(()=>setLoading(false), 3000);
        }, []);
          const [isShown, setIsShown] = useState(false);
          const handleClick = event => {
            setIsShown(current => !current);
          };
          const renderField = ({ label }) => (
            <div className="form-group">
              <label>{label}</label>
            </div>
          );


          if (hasTempData && hasTempData.length > 0) {
              // Navigate to another page
              navigate('/match');
            } else {
              // Render the content
              return (
                <div>
                  <Navbar bg="light" expand="lg" fixed="top">
                    <Container>
                      <Navbar.Brand href="#home">CDA</Navbar.Brand>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link href="/account">Home</Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                  {!loading ? (
                    <>
                   <Container style={{marginTop: '30%', marginBottom: '30%'}}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Grid style={{width: '100%', marginTop: "5%"}} container direction="column" alignItems="center" spacing={2}>
                    <Grid style={{width: 'inherit'}} item xs={12}>
                    {activeStep === 0 && (
                        <>
                  {/* <h3 style={{textDecoration: "underline"}}>Confirm Players Playing:</h3> */}
                  <p>Please confirm if all players listed are playing todays match. 
                    If a reserve is playing, please edit the player name that is absent with the Reserves name and then press next</p>
                  <hr></hr>
                  {doublesList.map((player, index) => (
                    <div key={index}>
                        <TextField id="outlined-basic" label="Player Name" variant="outlined" value={player.player1}
                          onChange={(e) => handlePlayerNameChange(index, 'player1', e.target.value)} style={{width: "100%", paddingBottom: "15px"}}/>
            {player.players.map((p) => {
              if (p.name === player.player1 && p.reserve === 'yes') {
                return (
                  <FormControl key={p.id} variant="outlined" style={{ width: '100%', paddingBottom: '15px' }}>
                    <InputLabel id={`singlesTypeLabel_${p.id}`}>Player Type</InputLabel>
                    <Select
                      labelId={`singlesTypeLabel_${p.id}`}
                      id={`singlesType_${p.id}`}
                      value={p.singlesType}
                      onChange={(e) => handlePlayerSinglesTypeChange(index, p.id, e.target.value)}
                      label="Player Type"
                    >
                      <MenuItem value="Ladies">Ladies</MenuItem>
                      <MenuItem value="Mens">Mens</MenuItem>
                    </Select>
                  </FormControl>
                );
              }
              return null;
            })}
            
                        <TextField id="outlined-basic" label="Player Name" variant="outlined" value={player.player2}
                          onChange={(e) => handlePlayerNameChange(index, 'player2', e.target.value)} style={{width: "100%", paddingBottom: "15px"}}/>
                          {player.players.map((p) => {
                    if (p.name === player.player2 && p.reserve === 'yes') {
                      return (
                        <FormControl key={p.id} variant="outlined" style={{ width: '100%', paddingBottom: '15px' }}>
                        <InputLabel id={`singlesTypeLabel_${p.id}`}>Player Type</InputLabel>
                        <Select
                          labelId={`singlesTypeLabel_${p.id}`}
                          id={`singlesType_${p.id}`}
                          value={p.singlesType}
                          onChange={(e) => handlePlayerSinglesTypeChange(index, p.id, e.target.value)}
                          label="Player Type"
                        >
                          <MenuItem value="Ladies">Ladies</MenuItem>
                          <MenuItem value="Mens">Mens</MenuItem>
                        </Select>
                      </FormControl>
                      );
                    }
                    return null;
                  })}
            
                    
                    </div>
                  ))}
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                  <h4>Select Doubles Order</h4>
                  <SortableList
                  items={doublesList}
                  onChange={setDoublesList}
                  renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                    <p style={{ marginBottom: '0rem' }}>{item.player1}</p>
                    {"\n"}
                    <p style={{ marginBottom: '0rem' }}>{item.player2}</p>
                      <SortableList.DragHandle />
                    </SortableList.Item>
                    
                  )}
                />
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <Typography variant="h6">Playing 1 & 2</Typography>
                          <SortableList
                  items={doublesIndexData}
                  onChange={setDoublesIndex1}
                  renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                     <p style={{ marginBottom: '0rem' }}>{item.name}</p>
                      <SortableList.DragHandle />
                    </SortableList.Item>
                    
                  )}
                />
                <Typography variant="h6">Playing 3 & 4</Typography>
                              <SortableList
                  items={doublesIndexData2}
                  onChange={setDoublesIndex2}
                  renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                      <p style={{ marginBottom: '0rem' }}>{item.name}</p>
                      <SortableList.DragHandle />
                    </SortableList.Item>
                    
                  )}
                />
                    <Typography variant="h6">Playing 5 & 6</Typography>
                              <SortableList
                  items={doublesIndexData3}
                  onChange={setDoublesIndex3}
                  renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                     <p style={{ marginBottom: '0rem' }}>{item.name}</p>
                      <SortableList.DragHandle />
                    </SortableList.Item>
                    
                  )}
                />
                        </>
                      )}
                      {activeStep === 3 && (
                        <>
                          <Typography variant="h6">Submit order of play</Typography>
                          <div>
                          <Card style={{ width: '100%', marginBottom: "10px", textAlign: "center" }}>
                  <Card.Header>Playing 1 & 2</Card.Header>
                  {doublesIndexData.map(function(d, id){
                     return (
                      <>
                  <ListGroup variant="flush">
                    <ListGroup.Item key={id}>{d.name}</ListGroup.Item>
                  </ListGroup>
                      </>
                     )
                   })}
                       </Card>
                   <Card style={{ width: '100%', marginBottom: "10px", textAlign: "center"}}>
                  <Card.Header>Playing 3 & 4</Card.Header>
                  {doublesIndexData2.map(function(d, idx){
                     return (
                      <>
                  <ListGroup variant="flush">
                    <ListGroup.Item key={idx}>{d.name}</ListGroup.Item>
                  </ListGroup>
                      </>
                     )
                   })}
                       </Card>
                       <Card style={{ width: '100%', marginBottom: "10px", textAlign: "center" }}>
                  <Card.Header>Playing 4 & 5</Card.Header>
                  {doublesIndexData3.map(function(d, ids){
                     return (
                      <>
                  <ListGroup variant="flush">
                    <ListGroup.Item key={ids}>{d.name}</ListGroup.Item>
                  </ListGroup>
                      </>
                     )
                   })}
                       </Card>
                  </div>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                    {activeStep > 0 && (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{backgroundColor: "#2C4945"}}
                          onClick={handleBack}
                          sx={{ marginRight: 8 }}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{backgroundColor: "#1781AA"}}
                        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
                    </>
                  ):(
                    <>
             <text>...Loading Please Wait</text>
                    </>
                  )}
                
                </div>
              );
            }
}
