import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getAuth, onAuthStateChanged, updateEmail } from "firebase/auth";
import { UserAuth } from "../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Issues on this page:
// - No way to return to the previous page without manually going back.
// - Password does not get updated
// - User name does not get updated
// - Username validation sees input field as email

const Profile = () => {
  const auth = getAuth();
  const { user } = UserAuth();
  const [newEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    onAuthStateChanged(auth, (user) => {
      console.log("Old Email", user.email);
      console.log("Old", user.displayName);
      updateEmail(user, newEmail)
        .then(() => {
          console.log("email updated");
          navigate("/account");
          toast.success("Profile Updated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((e) => {
          console.log(e);
          toast.error("an error has occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    });
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div>
        <h1 className="text-2xl font-bold py-2">Update Profile</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            placeholder="Enter Username"
            defaultValue={user.displayName}
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3"
            type="email"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            placeholder="Enter email"
            defaultValue={user.email}
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3"
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3"
            type="password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
    </div>
  );
};

export default Profile;
