import {React, useState, useEffect} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { getFirestore, getDocs, collection, writeBatch, doc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import Paper from '@mui/material/Paper';
//Issues on this page:
// - Prac-day in the Navbar has a href that goes nowhere

export default function CustomizedAccordions() {
  const { user } = UserAuth();
  const [customersData, setCustomersData] = useState([]);
  const db = getFirestore();


  const [fixtureList, setFixtureList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const fixturesData = await getDocs(collection(db, "fixtures"));
  //       const fixtures = fixturesData.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.id,
  //       }));
  //       setFixtureList(fixtures);
  
  //       const filterData = await getDocs(collection(db, "match"));
  //       const filters = filterData.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.id,
  //       }));
  //       setFilterList(filters);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  
  //   getData();
  // }, []);

  useEffect(() => {
    const getData = async () => {
      const currentDate = new Date();
      const lastCacheDate = localStorage.getItem("cacheDate");
      const sixDaysInMillis = 6 * 24 * 60 * 60 * 1000; // 6 days in milliseconds
  
      // Check if cache exists and has not expired
      if (
        lastCacheDate &&
        currentDate.getTime() - new Date(lastCacheDate).getTime() < sixDaysInMillis &&
        fixtureList.length === 0 &&
        filterList.length === 0
      ) {
        setFixtureList(JSON.parse(localStorage.getItem("fixtures")));
        setFilterList(JSON.parse(localStorage.getItem("filterFix")));
      } else {
        try {
          const fixturesData = await getDocs(collection(db, "fixtures"));
          const fixtures = fixturesData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setFixtureList(fixtures);
  
          const filterData = await getDocs(collection(db, "match"));
          const filters = filterData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setFilterList(filters);
  
          localStorage.setItem("fixtures", JSON.stringify(fixtures));
          localStorage.setItem("filterFix", JSON.stringify(filters));
          localStorage.setItem("cacheDate", currentDate.toString());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    getData();
  }, []); // Empty dependency array to ensure the effect runs only once
  
  
  
  
  console.log(fixtureList);
  console.log(filterList, "filter");
  
  const matchTeams = fixtureList.filter(
    (fixture) =>
      (fixture.team1 === user.displayName && !filterList.some((filter) => filter.matchId === fixture.id)) ||
      (fixture.team2 === user.displayName && !filterList.some((filter) => filter.matchId === fixture.id))
  );
  return (
    <div>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#home">CDA</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/account">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
      <h5 style={{display: "flex", justifyContent: "center", marginTop: '20%'}}>Upcoming League Games:</h5>
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      aria-label="contacts"
    >
{matchTeams
  .sort((a, b) => {
    const startDateOfYear = new Date(new Date().getFullYear(), 0, 1); // Start date of the current year
    const dateA = new Date(a.date.seconds * 1000);
    const dateB = new Date(b.date.seconds * 1000);
    const diffA = Math.abs(startDateOfYear - dateA);
    const diffB = Math.abs(startDateOfYear - dateB);
    return diffA - diffB;
  })
  .map((data) => (
    <div key={data.id}>
      <Paper elevation={3} style={{ borderRadius: "15px" }}>
        <ListItem disablePadding style={{ marginBottom: "10px" }}>
          <ListItemButton href="/order-of-players">
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText
              primary={data.team1 + " vs " + data.team2}
              secondary={
                "venue: " +
                data.venue +
                " " +
                new Date(
                  data.date.seconds * 1000
                ).toLocaleString()
              }
              onClick={() => {
                localStorage.setItem("matchSelected", data.id);
                localStorage.setItem("matchDate",  new Date(
                  data.date.seconds * 1000
                ).toLocaleString())
              }}
            />
          </ListItemButton>
        </ListItem>
      </Paper>
    </div>
  ))}



      {/* {matchTeams.map( data  => (
        
      <div key={data.id}>
      <ListItem disablePadding>
        <ListItemButton href="/order-of-players">
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary={data.team1 + " vs " + data.team2} secondary={"venue: " + data.venue + " " + data.date.toDate().toLocaleString()} onClick={() => {
          localStorage.setItem('matchSelected', data.id);
        }} />
        </ListItemButton>
      </ListItem>
      </div>
    ))} */}
    </List>
    </div>
    </div>
  );
}