import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { collection, query, where, onSnapshot, getFirestore, getDocs } from "firebase/firestore";
import Logo from './images/logo.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//Issues on this page:
// - no validation on email field
// - Password field is unprotected (seen as normal input field)
// - No error message when sign in failed
// - Error is console logged

const Signin = () => {
  const db = getFirestore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const setUser = [];
  const setUsers = [];
  const [loading , setLoading] = useState(false);
  const q = query(collection(db, "captains"), where("fullName", "==", email));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      setUser.push(doc.data().emailAccount);
    });
    return unsubscribe
  });

console.log(setUser)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    setError("");
    try {
      await signIn(setUser[0], password);
      setLoading(false);
      navigate("/account");
    } catch (e) {
      setError(e.message);
      setLoading(false);
      console.log(e.message);
    }
  };
console.log(setUsers)

  return (
    <div className="max-w-[700px] mx-auto my-16">
      
      {loading && (
        <div className="overlay">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
            style={{
              color: 'rgba(235, 227, 0, 1)', // Primary color with opacity 1
              position: 'absolute',
              top: '50%',
              left: '46%',
              height: '4%',
              transform: 'translate(-50%, -50%)',
              zIndex: '2',
            }}
          />
        </div>
      )}
      <div>
      <img src={Logo} alt="Image" className="contained-image" style={{
          width: '300px',
          height: 'auto',
          margin: '0 auto', // Center horizontally
        }} />
      </div>
      <h1 className="text-2xl font-bold py-2">Sign in to League</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            placeholder="Enter Username"
            onChange={(e) => setEmail(e.target.value)}
            className="border p-3"
            type="text"
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            className="border p-3"
            type="text"
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Sign In
        </Button>
        <p className="py-2">
          Don't have an account yet?{" "}
          <Link to="/signup" className="underline">
            Sign up.
          </Link>
        </p>
      </Form>
    </div>
  );
};

export default Signin;
