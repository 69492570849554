import React, { useState, useEffect } from 'react';
import { getFirestore, getDocs, collection, query, where } from 'firebase/firestore';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default function CustomizedAccordions() {
  const db = getFirestore();
  const [stats, setStats] = useState([]);
  useEffect(() => {
    const getData = async () => {
      if (
        localStorage.getItem("stats") !== null &&
        stats.length !== 0 &&
        localStorage.getItem("lastUpdated")
      ) {
        const currentTimeStamp = new Date().getTime();
        const storedTimeStamp = parseInt(localStorage.getItem("lastUpdated"), 10);
        const twoMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes * 60 seconds * 1000 milliseconds
  
        if (currentTimeStamp - storedTimeStamp < twoMinutesInMilliseconds) {
          setStats(JSON.parse(localStorage.getItem("stats")));
          return;
        }
      }
  
      const querySnapshot = await getDocs(query(collection(db, "match")));
      const statsData = querySnapshot.docs.map((doc) => doc.data());
      setStats(statsData);
      localStorage.setItem("stats", JSON.stringify(statsData));
      localStorage.setItem("lastUpdated", new Date().getTime().toString());
    };
  
    getData();
  
    const interval = setInterval(getData, 2 * 60 * 1000); // Refresh every 2 minutes
  
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("stats", JSON.stringify(stats));
  }, [stats]);


  const handleDownloadPdf = async () => {
    const doc = new jsPDF('p', 'pt', 'a4'); // Set page size and orientation
  
    // Generate tables using Autotable
    if (newLeaderboardDoublesMixed.length > 0) {
      doc.addPage();
      generateTable(doc, newLeaderboardDoublesMixed, 'Mixed Doubles', 20);
    }
  
    if (newLeaderboardDoublesLadies.length > 0) {
      doc.addPage();
      generateTable(doc, newLeaderboardDoublesLadies, 'Ladies Doubles', 20);
    }
  
    if (newLeaderboardDoublesMens.length > 0) {
      doc.addPage();
      generateTable(doc, newLeaderboardDoublesMens, 'Mens Doubles', 20);
    }
  
    if (mensLeaderboard.length > 0) {
      doc.addPage();
      generateTable(doc, mensLeaderboard, 'Mens Singles', 20);
    }
  
    if (ladiesLeaderboard.length > 0) {
      doc.addPage();
      generateTable(doc, ladiesLeaderboard, 'Ladies Singles', 20);
    }
  
    if (teamGames.length > 0) {
      doc.addPage();
      generatePointsTableData(doc, teamGames[0], 'Points Won', 20);
    }
  
    if (remappedData.length > 0) {
      doc.addPage('a4', 'landscape'); // Set the page orientation to landscape
      generateDetailedTeamGamesTable(doc, remappedData, 'Detailed Points Won per Game - Not in ranking Order', 5, 20);
    }
    if (teamGames1001.length > 0) {
      doc.addPage();
      generate1001Table(doc, teamGames1001, 'Total 1001 Team Games won', 20);
    }
    // Save the PDF
    doc.save('leaderboard.pdf');
  };
  
  // Rest of the code remains the same...
  
  
  const generateTable = (doc, data, type, startY) => {
    if (data.length === 0) {
      return; // Return early if there is no data available
    }
  
    const headers = ['Position', 'Player', 'Team', 'Games Played', 'Games Won', 'Discipline'];
    const rows = data.map((option) => [
      option.rank,
      option.player1 && option.doublesPartner ? `${option.player1} & ${option.doublesPartner}` : option.player,
      option.team,
      option.count || 0,
      option.doublesWon || option.singlesWon || 0,
      option.doublesType || option.singlesType,
    ]);
  
    doc.text(type, 10, startY); // Add a heading for the table
  
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: startY + 10,
      theme: 'striped',
    });
  };
  
  const generatePointsTableData = (doc, data, type, startY) => {
    if (data.length === 0) {
      return; // Return early if there is no data available
    }
  
    const headers = ['Position', 'Team', 'Points Won', 'Games Won'];
    const rows = data.map((option) => [option.rank, option.team, option.points, option.gamesWon]);
  
    doc.text(type, 10, startY); // Add a heading for the table
  
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: startY + 10,
      theme: 'striped',
    });
  };
  
  const generateDetailedTeamGamesTable = (doc, data, type, startX, startY) => {
    if (data.length === 0) {
      return; // Return early if there is no data available
    }
  
    const dates = [...new Set(data.flatMap(({ datePoints }) => datePoints.map(({ date }) => date)))];
    const headers = ['Club','Team', ...dates];
  
    const rows = data.map(({clubName ,team, datePoints }) => {
      const points = dates.map((date) => {
        const matchingPoint = datePoints.find(({ date: d }) => d === date);
        return matchingPoint ? matchingPoint.points : '';
      });
      return [clubName, team, ...points];
    });
  console.log("Rows",data);
    doc.text(type, startX, startY); // Add a heading for the table
    doc.autoTable({
      head: [headers],
      body: rows,
      startX: startX + 20,
      startY: startY + 10,
      theme: 'striped',
    });
  };
  
  const generate1001Table = (doc, data, type, startY) => {
    if (data.length === 0) {
      return; // Return early if there is no data available
    }
  
    const headers = ['Rank', 'Club Name', 'Team', 'Total Games Won'];
    const rows = data.map(({ ranking, clubName, team ,gamesWon }) => [
      ranking,
      clubName,
      team,
      gamesWon
    ]);
  
    doc.text(type, 10, startY); // Add a heading for the table
  
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: startY + 10,
      theme: 'striped',
    });
  };
  
  
  let doublesData = [];
  let singlesData = [];
  let results = [];
  let newLeaderboard = [];
  let newLeaderboardSingles = [];
  let newLeaderboardachievements = [];
// Modify the existing code
let achievementsData = stats.flatMap(({ achievements }) => achievements);
let results180 = [];
let results171 = [];

if (achievementsData.length > 0) {
  results180 = achievementsData
    .filter(({ oneEighty }) => oneEighty !== undefined)
    .map(({ fullName, oneEighty }) => ({
      player1: fullName,
      score: oneEighty,
    }))
    .sort((a, b) => b.score - a.score);

  results171 = achievementsData
    .filter(({ oneSevenOnes }) => oneSevenOnes !== undefined)
    .map(({ fullName, oneSevenOnes }) => ({
      player1: fullName,
      score: oneSevenOnes,
    }))
    .sort((a, b) => b.score - a.score);

  const calculateRankings = (results) => {
    let currentRank = 1;
    let currentScore = results[0]?.score;
    let rankings = [];

    for (let i = 0; i < results.length; i++) {
      const { player1, score } = results[i];
      if (score !== currentScore) {
        currentRank = i + 1;
        currentScore = score;
      }
      rankings.push({
        player1,
        score,
        rank: currentRank,
      });
    }

    return rankings;
  };

  const ranked180 = calculateRankings(results180);
  const ranked171 = calculateRankings(results171);

const mergedResults = [...ranked180, ...ranked171].reduce((acc, { player1, score, rank }) => {
  const existingPlayer = acc.find((item) => item.player1 === player1);

  if (existingPlayer) {
    existingPlayer.oneEighty = existingPlayer.oneEighty ? existingPlayer.oneEighty + score : score;
    existingPlayer.oneSevenOnes = existingPlayer.oneSevenOnes ? existingPlayer.oneSevenOnes + score : score;
    existingPlayer.rank180 = Math.max(existingPlayer.rank180, rank);
    existingPlayer.rank171 = Math.max(existingPlayer.rank171, rank);
    existingPlayer.totalRank = Math.min(existingPlayer.totalRank, rank);
  } else {
    acc.push({
      player1,
      oneEighty: score,
      oneSevenOnes: score,
      rank180: rank,
      rank171: rank,
      totalRank: rank,
    });
  }

  return acc;
}, []);


  newLeaderboardachievements = mergedResults.sort((a, b) => a.totalRank - b.totalRank);
}





console.log("achievements",achievementsData)
doublesData = stats.flatMap(({ doublesWinners }) => doublesWinners);

if (doublesData.length > 0) {
  results = doublesData.map(({ player1, doublesPartner, team, type, result }) => ({
    player1, // Destructure the `player1` property correctly
    doublesPartner: doublesPartner,
    team: team,
    type,
    result,
  }));
}

newLeaderboard = results.reduce((acc, { player1, doublesPartner, team, type, result }) => {
  const pairIndex = acc.findIndex(
    (item) =>
      (item.player1 === player1 && item.doublesPartner === doublesPartner) ||
      (item.player1 === doublesPartner && item.doublesPartner === player1)
  );

  if (pairIndex !== -1) {
    const existingPair = acc[pairIndex];
    if (result === "Winner") {
      existingPair.doublesWon++;
    } else if (result === "Lost") {
      existingPair.doublesLost++;
    }
    existingPair.count++; // Increment count for games played
  } else {
    const doublesWon = result === "Winner" ? 1 : 0;
    const doublesLost = result === "Lost" ? 1 : 0;
    acc.push({ player1, doublesPartner, doublesWon, doublesLost, team, doublesType: type, count: 1 });
  }

  return acc;
}, [])
  .sort((a, b) => b.doublesWon - a.doublesWon)
  .map((item, index) => ({
    ...item,
    rank: index + 1,
  }));

  // Separate the leaderboard by doubles types (mixed, ladies, mens)
  let newLeaderboardDoublesMixed = newLeaderboard.filter(option => option.doublesType === "mixed Doubles");
  let currentRankDoublesMixed = 1;
  let currentWinsDoublesMixed = newLeaderboardDoublesMixed[0]?.doublesWon;
  newLeaderboardDoublesMixed = newLeaderboardDoublesMixed.map((item, index) => {
    if (item.doublesWon === currentWinsDoublesMixed) {
      return {
        ...item,
        rank: currentRankDoublesMixed,
      };
    } else {
      currentRankDoublesMixed = index + 1;
      currentWinsDoublesMixed = item.doublesWon;
      return {
        ...item,
        rank: currentRankDoublesMixed,
      };
    }
  });

  let newLeaderboardDoublesLadies = newLeaderboard.filter(option => option.doublesType === "ladies Doubles");
  let currentRankDoublesLadies = 1;
  let currentWinsDoublesLadies = newLeaderboardDoublesLadies[0]?.doublesWon;
  newLeaderboardDoublesLadies = newLeaderboardDoublesLadies.map((item, index) => {
    if (item.doublesWon === currentWinsDoublesLadies) {
      return {
        ...item,
        rank: currentRankDoublesLadies,
      };
    } else {
      currentRankDoublesLadies = index + 1;
      currentWinsDoublesLadies = item.doublesWon;
      return {
        ...item,
        rank: currentRankDoublesLadies,
      };
    }
  });

  let newLeaderboardDoublesMens = newLeaderboard.filter(option => option.doublesType === "mens Doubles");
  let currentRankDoublesMens = 1;
  let currentWinsDoublesMens = newLeaderboardDoublesMens[0]?.doublesWon;
  newLeaderboardDoublesMens = newLeaderboardDoublesMens.map((item, index) => {
    if (item.doublesWon === currentWinsDoublesMens) {
      return {
        ...item,
        rank: currentRankDoublesMens,
      };
    } else {
      currentRankDoublesMens = index + 1;
      currentWinsDoublesMens = item.doublesWon;
      return {
        ...item,
        rank: currentRankDoublesMens,
      };
    }
  });

  console.log("all",results);
  console.log("Doubles Mixed",newLeaderboardDoublesMixed);
  console.log(newLeaderboardDoublesLadies);
  console.log(newLeaderboardDoublesMens);

console.log(newLeaderboard)
   singlesData = stats.flatMap(({ singlesWinners }) => singlesWinners);
  if (singlesData.length > 0) {
    results = singlesData.map(({ winner, team, type, result, loser }) => ({
      winner,
      team,
      type,
      result,
      loser,
    }));
    newLeaderboardSingles = results.reduce((acc, { winner, team, type, result, loser }) => {
      if (result === "Winner") {
        const existingPlayer = acc.find((item) => item.player === winner && item.singlesType === type);
        if (existingPlayer) {
          existingPlayer.singlesWon++;
          existingPlayer.count++;
        } else {
          acc.push({ player: winner, team, singlesWon: 1, singlesLost: 0, singlesType: type, count: 1 });
        }
      } else if (result === "Lost") {
        const existingPlayer = acc.find((item) => item.player === loser && item.singlesType === type);
        if (existingPlayer) {
          existingPlayer.singlesLost++;
          existingPlayer.count++;
        } else {
          acc.push({ player: loser, team, singlesWon: 0, singlesLost: 1, singlesType: type, count: 1 });
        }
      }
      return acc;
    }, [])
      .sort((a, b) => b.singlesWon - a.singlesWon)
      .map((item, index) => ({
        ...item,
        rank: index + 1,
      }));
    
    // Add functionality to share ranks for players with the same number of games won
    let currentRankSingles = 1;
    let currentWinsSingles = newLeaderboardSingles[0].singlesWon;
    newLeaderboardSingles = newLeaderboardSingles.map((item, index) => {
      if (item.singlesWon === currentWinsSingles) {
        return {
          ...item,
          rank: currentRankSingles,
        };
      } else {
        currentRankSingles = index + 1;
        currentWinsSingles = item.singlesWon;
        return {
          ...item,
          rank: currentRankSingles,
        };
      }
    });
  }
  let mensLeaderboard = [];
  let ladiesLeaderboard = [];
  let currentRankSinglesMens = 0;
  let currentWinsSinglesMens = -1;
  let currentRankSinglesLadies = 0;
  let currentWinsSinglesLadies = -1;
  
  newLeaderboardSingles.forEach((item) => {
    if (item.singlesType === 'Mens') {
      if (item.singlesWon !== currentWinsSinglesMens) {
        currentRankSinglesMens++;
        currentWinsSinglesMens = item.singlesWon;
      }
      const playerData = {
        ...item,
        rank: currentRankSinglesMens,
      };
      mensLeaderboard.push(playerData);
    } else if (item.singlesType === 'Ladies') {
      if (item.singlesWon !== currentWinsSinglesLadies) {
        currentRankSinglesLadies++;
        currentWinsSinglesLadies = item.singlesWon;
      }
      const playerData = {
        ...item,
        rank: currentRankSinglesLadies,
      };
      ladiesLeaderboard.push(playerData);
    }
  });
  let teamGames = [];
  const games = stats.flatMap(({ pointsWon }) => pointsWon);
  
  // Count points won and games won by each team
  const teamPoints = {};
  const teamGamesWon = {};
  games.forEach(({ teamOne, teamOnePoints, teamTwo, teamTwoPoints }) => {
    if (!teamPoints[teamOne]) {
      teamPoints[teamOne] = 0;
      teamGamesWon[teamOne] = 0;
    }
    if (!teamPoints[teamTwo]) {
      teamPoints[teamTwo] = 0;
      teamGamesWon[teamTwo] = 0;
    }
    
    teamPoints[teamOne] += teamOnePoints;
    teamPoints[teamTwo] += teamTwoPoints;
  
    if (teamOnePoints >= 6) {
      teamGamesWon[teamOne]++;
    }
  
    if (teamTwoPoints >= 6) {
      teamGamesWon[teamTwo]++;
    }
  });
  
  // Convert teamPoints and teamGamesWon objects to an array of objects
  const teamPointsArray = Object.entries(teamPoints).map(([team, points]) => ({
    team,
    points,
    gamesWon: teamGamesWon[team],
  }));
  
  // Sort teams based on points in descending order
  const sortedTeams = teamPointsArray.sort((a, b) => b.points - a.points);
  
  // Calculate rankings and assign ranks to teams
  let rank = 1;
  const rankedTeams = sortedTeams.map((team, index, array) => {
    if (index > 0 && team.points !== array[index - 1].points) {
      rank++;
    }
    return { ...team, rank };
  });
  
  teamGames.push(rankedTeams);
  
  console.log(rankedTeams);

  let remappedData = [];
  const detailedteam = stats.flatMap(({ pointsWon }) => pointsWon);
  
  detailedteam.forEach(({ clubOne, clubTwo, teamOne, teamOnePoints, teamTwo, teamTwoPoints, datePoints }) => {
    const teamOneData = remappedData.find(({ team, clubName }) => team === teamOne && clubName === clubOne);
    const teamTwoData = remappedData.find(({ team, clubName }) => team === teamTwo && clubName === clubTwo);
  
    // Extract the date from the "datePoints" string
    const date = datePoints.split(",")[0].trim();
  
    if (teamOneData) {
      const existingData = teamOneData.datePoints.find(({ date: d }) => d === date);
      if (existingData) {
        existingData.points += teamOnePoints;
      } else {
        teamOneData.datePoints.push({ date: date, points: teamOnePoints });
      }
    } else {
      remappedData.push({ team: teamOne, clubName: clubOne, datePoints: [{ date: date, points: teamOnePoints }] });
    }
    console.log("points", teamOneData);
  
    if (teamTwoData) {
      const existingData = teamTwoData.datePoints.find(({ date: d }) => d === date);
      if (existingData) {
        existingData.points += teamTwoPoints;
      } else {
        teamTwoData.datePoints.push({ date: date, points: teamTwoPoints });
      }
    } else {
      remappedData.push({ team: teamTwo, clubName: clubTwo, datePoints: [{ date: date, points: teamTwoPoints }] });
    }
  });
  
  
  
  console.log("remap",remappedData);
  let teamGamesData = {};
  let teamGames1001 = [];
  const games1001 = stats.flatMap(({ teamGamesWinners }) => teamGamesWinners);
  
  games1001.forEach(({ clubName, winner, date, team, loser }) => {
    if (!teamGamesData[team]) {
      teamGamesData[team] = {
        clubName,
        date,
        gamesWon: 0
      };
    }
    
    if (winner && team === winner) {
      teamGamesData[team].gamesWon++;
    }
  });
  
  // Convert teamGamesData object to an array
  const teamGamesArray = Object.entries(teamGamesData).map(([team, data]) => ({
    team,
    clubName: data.clubName,
    gamesWon: data.gamesWon,
    date: data.date
  }));
  
  // Sort the teams based on the number of games won in descending order
  const sortedTeams1001 = teamGamesArray.sort((a, b) => b.gamesWon - a.gamesWon);
  
  // Assign rankings to teams
  let rank1001 = 1;
  let prevGamesWon = -1;
  const rankedTeams1001 = sortedTeams1001.map((teamData, index) => {
    if (teamData.gamesWon !== prevGamesWon) {
      rank1001 = index + 1;
      prevGamesWon = teamData.gamesWon;
    }
    return { ...teamData, ranking: rank1001 };
  });
  
  teamGames1001 = rankedTeams1001;

  const games1001Detailed = stats.flatMap(({ teamGamesWinners }) => teamGamesWinners);

  // const transformedData = data.flatMap(item => {
  //   return item.teamGamesWinners.flatMap(game => {
  //     if (game.winner && game.loser) {
  //       const winnerClub = game.clubName;
  //       const winner = game.winner;
  //       const loserClub = item.teamGamesWinners.find(item => item.team === game.loser).clubName;
  //       const loser = game.loser;
  //       const winnerPoints = 1; // Assuming each game has a fixed individual game point of 1
  //       const loserPoints = 0;
  //       const date = game.date;
  
  //       return {
  //         winnerClub,
  //         winner,
  //         winnerPoints,
  //         loserClub,
  //         loser,
  //         loserPoints,
  //         date
  //       };
  //     }
  //   });
  // });
  
  console.log(games1001Detailed);
  
  
  

console.log(teamGames1001)

  
  


  return (
    <div>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#home">CDA Social League</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/account">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
    {!stats ? (
      <div>Loading...</div>
    ) : stats.length === 0 ? (
      <div>No data available.</div>
    ) : (
      // Render your component with the populated `stats` array
      <div style={{paddingTop: '18%'}}>
        <button type="button" onClick={handleDownloadPdf}>
        Download as PDF
      </button>
      <div id="print-section">
      {newLeaderboardDoublesMixed.length > 0 && (
      <Table striped responsive bordered>
        <thead>
          <tr>
            <th>Position</th>
            <th>Doubles List</th>
            <th>Games Played</th>
            <th>Games Won</th>
            <th>Doubles Type</th>
          </tr>
        </thead>
        <tbody>
          {newLeaderboardDoublesMixed.map((option, index) => (
            <tr key={index}>
              <td>{option.rank}</td>
              <td>{option.player1} & {option.doublesPartner}</td>
              <td>{option.count}</td>
              <td>{option.doublesWon}</td>
              <td>{option.doublesType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}

    {newLeaderboardDoublesLadies.length > 0 && (
      <Table striped responsive bordered>
        <thead>
          <tr>
            <th>Position</th>
            <th>Doubles List</th>
            <th>Games Played</th>
            <th>Games Won</th>
            <th>Doubles Type</th>
          </tr>
        </thead>
        <tbody>
          {newLeaderboardDoublesLadies.map((option, index) => (
            <tr key={index}>
              <td>{option.rank}</td>
              <td>{option.player1} & {option.doublesPartner}</td>
              <td>{option.count}</td>
              <td>{option.doublesWon}</td>
              <td>{option.doublesType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}

    {newLeaderboardDoublesMens.length > 0 && (
      <Table striped responsive bordered>
        <thead>
          <tr>
            <th>Position</th>
            <th>Doubles List</th>
            <th>Games Played</th>
            <th>Games Won</th>
            <th>Doubles Type</th>
          </tr>
        </thead>
        <tbody>
          {newLeaderboardDoublesMens.map((option, index) => (
            <tr key={index}>
              <td>{option.rank}</td>
              <td>{option.player1} & {option.doublesPartner}</td>
              <td>{option.count}</td>
              <td>{option.doublesWon}</td>
              <td>{option.doublesType}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}

{/* Render the "mens" table */}
{mensLeaderboard.length > 0 && (
  <Table striped responsive bordered>
    <thead>
      <tr>
        <th>Position</th>
        <th>Singles List</th>
        <th>Games Played</th>
        <th>Games Won</th>
        <th>Singles Type</th>
      </tr>
    </thead>
    <tbody>
      {mensLeaderboard.map((option, index) => (
        <tr key={index}>
          <td>{option.rank}</td>
          <td>{option.player}</td>
          <td>{option.count}</td>
          <td>{option.singlesWon}</td>
          <td>{option.singlesType}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)}

{/* Render the "ladies" table */}
{ladiesLeaderboard.length > 0 && (
  <Table striped responsive bordered>
    <thead>
      <tr>
        <th>Position</th>
        <th>Singles List</th>
        <th>Games Played</th>
        <th>Games Won</th>
        <th>Singles Type</th>
      </tr>
    </thead>
    <tbody>
      {ladiesLeaderboard.map((option, index) => (
        <tr key={index}>
          <td>{option.rank}</td>
          <td>{option.player}</td>
          <td>{option.count}</td>
          <td>{option.singlesWon}</td>
          <td>{option.singlesType}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)}

      </div>
      </div>
    )}
    </div>
    </div>
  );
}
